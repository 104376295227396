
import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import { Suspense, lazy } from 'react';
import "swiper/css";
const Home = lazy(() => import('./page/Home'));
const IndustriesApplications = lazy(() => import('./page/IndustriesApplications'));
const ProductsSolutions = lazy(() => import('./page/ProductsSolutions'));
const AboutVisionDynamics = lazy(() => import('./page/AboutVisionDynamics'));


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Suspense fallback={<div>Loading...</div>}> <Home/> </Suspense>} />
        <Route path='industries-applications' element={<Suspense fallback={<div>Loading...</div>}> <IndustriesApplications/> </Suspense>} />
        <Route path='products-solutions' element={<Suspense fallback={<div>Loading...</div>}> <ProductsSolutions/> </Suspense>} />
        <Route path='about-vision-dynamics' element={<Suspense fallback={<div>Loading...</div>}> <AboutVisionDynamics/> </Suspense>} />
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
