import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import "animate.css/animate.min.css";
const Layout = () => {
    return (
        <div className='font-Roboto'>

            <Header />
            <Outlet />
            <Footer/>
        </div>
    );
};

export default Layout;